<template>
  <div id="app">
    <!-- <div> -->
      <Navbar/>
    <!-- </div> -->
    <router-view/>
    <!-- <div> -->
      <Footer/>
    <!-- </div> -->
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components:{
    Navbar,
    Footer
  }
}
</script>

<style>
::-webkit-scrollbar{
    width: 6px;
    background-color: #F5F5F5;
}
::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb{
    background-color: #533E2D; 
    /* background-image: -webkit-linear-gradient(90deg,rgba(255, 255, 255, .2) 25%,transparent 25%,transparent 50%,rgba(255, 255, 255, .2) 50%,rgba(255, 255, 255, .2) 75%,transparent 75%,transparent) */
}
h1, h2, h3, h4{
  font-family: "Aladin", cursive;
  color: #533E2D;
}
p {
  /* font-family: 'Vollkorn', serif; */
  font-family: 'Rasa', serif;
}

#book {
  background-color: #533e2d;
  font-family: "Aladin", cursive;
}
#book:hover {
  background: #B88B4A;
  /* color: #242331 !important; */
  transition: all 0.2s ease;
}
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */
</style>
