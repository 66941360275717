<template>
  <div class="footer">
    <div class="footer_content">
      <div class="columnsfooter">
        <p id="titulo" class="title11">{{ $t("footer.links") }}</p>
        <router-link
          id="media"
          class="medialinks"
          style="text-decoration: none; color: #ddca7d"
          :to="{ name: 'Home', params: { lang: this.$i18n.locale } }"
        >
          {{ $t("menu.home") }}
        </router-link>
        <router-link
          id="media"
          class="medialinks"
          style="text-decoration: none; color: #ddca7d"
          :to="{ name: 'Rooms', params: { lang: this.$i18n.locale } }"
        >
          {{ $t("menu.rooms") }}
        </router-link>
        <router-link
          id="media"
          class="medialinks"
          style="text-decoration: none; color: #ddca7d"
          :to="{ name: 'About', params: { lang: this.$i18n.locale } }"
        >
          {{ $t("menu.about") }}
        </router-link>
        <router-link
          id="media"
          class="medialinks"
          style="text-decoration: none; color: #ddca7d"
          :to="{ name: 'Contact', params: { lang: this.$i18n.locale } }"
        >
          {{ $t("menu.contact") }}
        </router-link>
        <router-link
          id="media"
          class="medialinks"
          style="text-decoration: none; color: #ddca7d"
          :to="{ name: 'Gallery', params: { lang: this.$i18n.locale } }"
        >
          {{ $t("menu.gallery") }}
        </router-link>
        <router-link
          id="media"
          class="medialinks"
          style="text-decoration: none; color: #ddca7d"
          :to="{ name: 'Puno', params: { lang: this.$i18n.locale } }"
        >
          {{ $t("menu.puno") }}
        </router-link>
      </div>
      <div class="columnsfooter">
        <p id="titulo" class="title11">{{ $t("footer.media") }}</p>
        <div class="">
          <i class="bx bxl-facebook-square" style="color: #ddca7d; padding-right: 3px;"></i>
          <a
            id="media"
            href="https://www.facebook.com/mosoq.inn"
            target="_blank"
            class="medialinks"
            >Facebook</a
          >
        </div>
        <div class="">
          <i class="bx bxl-instagram" style="color: #ddca7d; padding-right: 3px;"></i>
          <a
            id="media"
            href="https://www.instagram.com/mosoqinn/"
            target="_blank"
            class="medialinks"
            >Instagram</a
          >
        </div>
      </div>
      <div class="columnsfooter">
        <p id="titulo" class="title11">{{ $t("footer.info") }}</p>
        <div class=" pb-2">
          <i class="bx bx-map" style="color: #ddca7d; padding-right: 3px;"></i>
          <a
            id="media"
            class="medialinks"
            href="https://www.google.com/maps/place/Mosoq+Inn+Hotel/@-15.8425985,-70.0256324,19z/data=!4m8!3m7!1s0x915d69934266e579:0xdbde5cba27081ffb!5m2!4m1!1i2!8m2!3d-15.8425985!4d-70.0250852"
            target="_blank"
            >Jr. Moquegua 673, Puno 21001 Perú</a
          >
        </div>
        <p id="titulo" class="title11">{{ $t("footer.contact") }}</p>
        <div class="">
          <i class="bx bxs-phone" style="color: #ddca7d; padding-right: 3px;"></i>
          <a
            id="media"
            class="medialinks"
            href="https://wa.link/7b7y0r"
            target="_blank"
            >+51 951 623 199</a
          >
        </div>
        <div class="">
          <i class="bx bxl-whatsapp" style="color: #ddca7d; padding-right: 3px;"></i>
          <a
            id="media"
            class="medialinks"
            href="https://wa.link/7b7y0r"
            target="_blank"
            >+51 951 623 199</a
          >
        </div>
        <div class="">
          <i class="bx bx-envelope" style="color: #ddca7d; padding-right: 3px;"></i>
          <a id="media" class="medialinks" href="" target="_blank"
            >reservas@mosoqinnperu.com</a
          >
        </div>
      </div>

      <div class="columnsfooter">
        <div class="logocolumn">
          <img
          src="../assets/logos/logo.png"
          class="logofooter"
          alt="mosoqinn logo"
        />
        <a id="media" class="medialinks"
          >"Un nuevo amanecer en un nuevo lugar"</a
        >
        </div>
      </div>
    </div>

    <div class="footer2">
      <div>
        <p class="copyright">
          Copyright &copy; 2021 by FLJ. All rights reserved
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.footer {
  color: #ddca7d;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.footer_content{
    display: flex;
    background-color: #242331;
  }
.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.columnsfooter{
  display: flex;
}
#titulo{
  font-family: "Aladin", cursive;
}
#media {
  color: #ddca7d;
  text-decoration: none;
  font-family: "Rasa", serif;
}
.footer2, .logocolumn{
  display: flex;
  justify-content: center;
}
.logocolumn{
    flex: 1;
    flex-direction: column;
    align-items: center;
  }
.footer2{
  background-color: #000;
  padding: 0;
}
.copyright {
  font-family: "Rasa", serif;
  color:#474747;
  font-size: 10px;
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .footer_content{
    flex-direction: row;
    padding: 1%;
  }
  .columnsfooter{
    align-items: center;
  }
  .columnsfooter{
    flex: 0.25;
    flex-direction: column;
  }
  .title11{
    font-size: 20px;
  }
  .medialinks{
    font-size: 16px;
  }
  .logofooter{
    width: 8rem;
  }
}
@media (min-width: 800px) and (max-width: 991px) {
  .footer_content{
    flex-direction: row;
    padding: 10px;
  }
  .columnsfooter{
    flex: 1;
    flex-direction: column;
    text-align: center;
  }
  .title11{
    font-size: 18px;
    margin-bottom: 2px;
  }
  .medialinks{
    font-size: 14px;
  }
  .logofooter{
    width: 7rem;
  }  
}
/* small-tablet-portrait: min:600px - max:799px; */
@media (min-width: 600px) and (max-width: 799px) {
  .footer_content{
    flex-direction: row;
    padding: 10px;
  }
  .columnsfooter{
    align-items: center;
    flex: 1;
    flex-direction: column;
    text-align: center;
  }
  .title11{
    font-size: 15px;
    margin-bottom: 2px;
  }
  .medialinks{
    font-size: 13px;
  }
  .logofooter{
    width: 6rem;
  }
}
/* mobile-landscape: min:480px - max:599px; */
@media (min-width: 480px) and (max-width: 599px) {
  .footer_content{
    flex-direction: column;
    padding: 10px;
  }
  .columnsfooter{
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1rem;
    padding-top: 1rem;
    border-top: dotted 1px #DDCA7D;
  }
  .title11{
    font-size: 20px;
    margin-bottom: 2px;
  }
  .medialinks{
    font-size: 15px;
  }
  .logofooter{
    width: 7rem;
  }
}
/* mobile-portrait: min:320px - max:479px; */
@media /*(min-width: 320px) and*/ (max-width: 479px) {
  .footer_content{
    flex-direction: column;
    padding: 10px;
  }
  .columnsfooter{
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 1rem;
    padding-top: 1rem;
    border-top: dotted 1px #DDCA7D;
  }
  .title11{
    font-size: 20px;
    margin-bottom: 2px;
  }
  .medialinks{
    font-size: 13px;
  }
  .logofooter{
    width: 7rem;
  }
}
</style>