import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '../i18n'

function load(component){
  return () => import(`@/views/${component}.vue`)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/:locale',
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale
      const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')

      if(!supported_locales.includes(locale)) return next('en')

      if(i18n.locale !== locale){
        i18n.locale = locale;
      }

      return next()
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: load('Home')
      },
      {
        path: 'about',
        name: 'About',
        component: load('About')
      },
      {
        path: 'rooms',
        name: 'Rooms',
        component: load('Rooms')
      },
      {
        path: 'contact',
        name: 'Contact',
        component: load('ContactUs')
      },
      {
        path: 'puno',
        name: 'Puno',
        component: load('Puno')
      },
      {
        path: 'gallery',
        name: 'Gallery',
        component: load('Gallery')
      },
      {
        path: 'roomDetail',
        name: 'RoomDetails',
        component: load('RoomDetails')
      },
      {
        path: 'book',
        name: 'Book',
        component: load('Book')
      },
      {
        path: 'checkout',
        name: 'CheckOut',
        component: load('CheckOut')
      },
      {
        path: 'standby',
        name: 'StandBy',
        component: load('StandBy')
      }
    ]
  },
  {
    path: '*',
    redirect(){
      return process.env.VUE_APP_I18N_LOCALE;
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
