import Vue from 'vue'
import Vuex from 'vuex'

// Imagenes
import doble1 from '../assets/fotos/doble1.jpg'
import triple1 from '../assets/fotos/triple1.jpg'
import simple2 from '../assets/fotos/simple2.jpg'
import matri1 from '../assets/fotos/matri1.jpg'


Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        topRated: [
            {
                id: 1,
                name: 'single',
                price: 45,
                url: simple2,
                desc: "holi e,e",
                rate: 4.1
            },
            {
                id: 2,
                name: 'double',
                price: 55,
                url: doble1,
                desc: "holi doble e,e",
                rate: 4.7
            },
            {
                id: 3,
                name: 'matri',
                price: 70,
                url: matri1,
                desc: "holi matri e,e",
                rate: 4.3
            },
            {
                id: 4,
                name: 'triple',
                price: 85,
                url: triple1,
                desc: "holi triple e,e",
                rate: 4.5
            },
            {
                id: 5,
                name: 'quadruple',
                price: 85,
                url: triple1,
                desc: "holi quadruple e,e",
                rate: 4.5
            }
        ],
        cartItemCount: 0,
        cartItems:[],
        checkin: '',
        checkout: '',
        days: 1,
    },
    mutations:{
        addToCart(state, payload){
            let item = payload;
            item = {...item, quantity: 1}
            if(state.cartItems.length > 0){
                let bool = state.cartItems.some(i => i.id == item.id)
                if(bool){
                    let itemIndex = state.cartItems.findIndex(el => el.id === item.id)
                    state.cartItems[itemIndex]["quantity"] += 1;
                } else {
                    state.cartItems.push(item)
                }
            } else {
                state.cartItems.push(item)
            }
            state.cartItemCount++
        },
        removeItem(state, payload){
            if(state.cartItems.length > 0){
                let bool = state.cartItems.some(i => i.id == payload.id)
                if(bool){
                    let index = state.cartItems.findIndex(el => el.id === payload.id)
                    if(state.cartItems[index]["quantity"] !== 0){
                        state.cartItems[index]["quantity"] -= 1
                        state.cartItemCount--
                    }
                    if(state.cartItems[index]["quantity"] === 0){
                        state.cartItems.splice(index,1)
                    }
                }
            }
        },
        addToCheckIn(state, payload){
            state.checkin = payload;
        },
        addToCheckOut(state, payload){
            state.checkout = payload;
        },
        addToDays(state, payload){
            state.days = payload;
        }
    },
    actions: {
        addToCart: (context, payload) => {
            context.commit("addToCart", payload)
        },
        removeItem: (context, payload) => {
            context.commit("removeItem", payload)
        },
        addToCheckIn: ({commit}, payload)=>{
            commit("addToCheckIn",payload)
        },
        addToCheckOut: ({commit}, payload)=>{
            commit("addToCheckOut",payload)
        },
        addToDays: ({commit}, payload)=>{
            commit("addToDays",payload)
        }
    }
})