<template>
  <div class="losw">
    <!-- <ul > -->
      <li v-for="locale in locales" :key="locale" @click="switchLocale(locale)">
        <!-- {{locale}} -->
        <img :src="icons[locale]" class="country-icon" />
      </li>
    <!-- </ul> -->
  </div>
</template>

<script>
import enIcon from "../assets/flags/united-states.svg";
import esIcon from "../assets/flags/spain.svg";

export default {
  name: "LocaleSwitcher",
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        const to = this.$router.resolve({ params: { locale } });
        this.$router.push(to.location);
      }
    },
  },
  data() {
    return {
      locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(","),
      icons: {
        en: enIcon,
        es: esIcon,
      },
    };
  },
};
</script>

<style >
/* #lista1 li {
  display: inline;
  padding-left: 3px;
  padding-right: 3px;
} */
.losw{
  display: flex;
}
.country-icon {
  width: 20px;
  height: auto;
  display: inline-block;
  margin-right: .5rem;
  /* vertical-align: baseline; */
  /* box-shadow: 0px 1px 3px rgba(24, 29, 38, 0.1); */
}
/* .country-icon.as-toggle {
  margin-top: 1px;
} */

</style>