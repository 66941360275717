<template>
  <div class="navbar">
    <b-navbar fixed="top" class="navb">
      <img id="logo" src="../assets/logos/logo.png" alt="mosoqinn logo" />
      <b-navbar-brand href="/" class="" id="brand"> MOSOQ INN </b-navbar-brand>

      <div class="navoptions">
        <b-navbar-nav class="">
          <b-nav-item class="navitem">
            <router-link
              style="text-decoration: none; color: #ddca7d"
              :to="{ name: 'Home', params: { lang: this.$i18n.locale } }"
            >
              {{ $t("menu.home") }}
            </router-link>
          </b-nav-item>
          <b-nav-item class="navitem">
            <router-link
              style="text-decoration: none; color: #ddca7d"
              :to="{ name: 'Rooms', params: { lang: this.$i18n.locale } }"
            >
              {{ $t("menu.rooms") }}
            </router-link>
          </b-nav-item>
          <b-nav-item class="navitem">
            <router-link
              style="text-decoration: none; color: #ddca7d"
              :to="{ name: 'About', params: { lang: this.$i18n.locale } }"
            >
              {{ $t("menu.about") }}
            </router-link>
          </b-nav-item>
          <b-nav-item class="navitem">
            <router-link
              style="text-decoration: none; color: #ddca7d"
              :to="{ name: 'Contact', params: { lang: this.$i18n.locale } }"
            >
              {{ $t("menu.contact") }}
            </router-link>
          </b-nav-item>
          <b-nav-item class="navitem">
            <router-link
              style="text-decoration: none; color: #ddca7d"
              :to="{ name: 'Gallery', params: { lang: this.$i18n.locale } }"
            >
              {{ $t("menu.gallery") }}
            </router-link>
          </b-nav-item>
          <b-nav-item class="navitem">
            <router-link
              style="text-decoration: none; color: #ddca7d"
              :to="{ name: 'Puno', params: { lang: this.$i18n.locale } }"
            >
              {{ $t("menu.puno") }}
            </router-link>
          </b-nav-item>
        </b-navbar-nav>
      </div>

      <!-- Right aligned nav items -->
      <!-- LANGUAGE SWITCHER -->
      <b-navbar-nav class="localesw">
        <b-nav-item rigth>
          <LocaleSwitcher />
        </b-nav-item>
      </b-navbar-nav>

      <div>
        <div class="sidemenu">
          <b-button class="" variant="outline" v-b-toggle.sidebar-right>
            <i class="bx bx-menu bx-sm" style="color: #ddca7d"></i>
          </b-button>
        </div>
        <b-sidebar
          id="sidebar-right"
          bg-variant="cafeNoir"
          text-variant="inherit"
          width="10rem"
          class=""
          right
          shadow
        >
          <div class="px-3 py-2">
            <nav class="mb-3">
              <b-nav vertical>
                <b-nav-item class="">
                  <router-link
                    style="text-decoration: none; color: #ddca7d"
                    :to="{ name: 'Home', params: { lang: this.$i18n.locale } }"
                  >
                    {{ $t("menu.home") }}
                  </router-link>
                </b-nav-item>
                <b-nav-item class="">
                  <router-link
                    style="text-decoration: none; color: #ddca7d"
                    :to="{ name: 'Rooms', params: { lang: this.$i18n.locale } }"
                  >
                    {{ $t("menu.rooms") }}
                  </router-link>
                </b-nav-item>
                <b-nav-item class="">
                  <router-link
                    style="text-decoration: none; color: #ddca7d"
                    :to="{ name: 'About', params: { lang: this.$i18n.locale } }"
                  >
                    {{ $t("menu.about") }}
                  </router-link>
                </b-nav-item>
                <b-nav-item class="">
                  <router-link
                    style="text-decoration: none; color: #ddca7d"
                    :to="{
                      name: 'Contact',
                      params: { lang: this.$i18n.locale },
                    }"
                  >
                    {{ $t("menu.contact") }}
                  </router-link>
                </b-nav-item>
                <b-nav-item class="">
                  <router-link
                    style="text-decoration: none; color: #ddca7d"
                    :to="{
                      name: 'Gallery',
                      params: { lang: this.$i18n.locale },
                    }"
                  >
                    {{ $t("menu.gallery") }}
                  </router-link>
                </b-nav-item>
                <b-nav-item class="">
                  <router-link
                    style="text-decoration: none; color: #ddca7d"
                    :to="{ name: 'Puno', params: { lang: this.$i18n.locale } }"
                  >
                    {{ $t("menu.puno") }}
                  </router-link>
                </b-nav-item>
              </b-nav>
            </nav>
          </div>
        </b-sidebar>
      </div>
    </b-navbar>
  </div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";

export default {
  components: {
    LocaleSwitcher,
  },
  data() {
    return {};
  },
};
</script>

<style>
.navbar {
  font-family: "Aladin", cursive;
  background-color: #533e2d;
}
#logo {
  width: 2.5rem;
  margin-right: 1rem;
}
#brand {
  color: #ddca7d;
}
#sidebar-right {
  color: #ddca7d;
  background-color: #533e2d;
}
.localesw {
  margin-left: auto;
  margin-right: 1%;
}

/* small-tablet-landscape: min:800px - max:767px; */
/* small-tablet-landscape: min:800px - max:767px; */
/* tablet-portrait: min:768px - max:1023px; */
/* tablet-landscape: min:1024px; */

@media (min-width: 992px) {
  .navitem {
    margin: 0 0.5rem;
    display: inline;
  }
}
@media (min-width: 800px) and (max-width: 991px) {
  .navitem {
    display: none;
  }
}
/* small-tablet-portrait: min:600px - max:799px; */
@media (min-width: 600px) and (max-width: 799px) {
  .navitem {
    display: none;
  }
}
/* mobile-landscape: min:480px - max:599px; */
@media (min-width: 480px) and (max-width: 599px) {
  .navitem {
    display: none;
  }
}
/* mobile-portrait: min:320px - max:479px; */
@media /*(min-width: 320px) and*/ (max-width: 479px) {
  .navitem {
    display: none;
  }
}
</style>